import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import useStores from '@supplyhound/hooks/useStores'
import { ID, UserProfile, BasicUser, USER_EDITABLE_ATTRS } from '@supplyhound/types'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import DropdownField from '@supplyhound/forms/fields/DropdownField'
import * as Yup from 'yup'
import { catchErrors } from '@supplyhound/utils/forms'
import { pick } from 'lodash'
import Spacer from '@supplyhound/components/common/Spacer'
import { formatPhoneNumber } from '@supplyhound/utils/formatters'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import HorizontalRule from '@supplyhound/components/common/HorizontalRule'
import { useIonModal } from '@ionic/react'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import { personOutline } from 'ionicons/icons'
import BaseButton from '@supplyhound/components/buttons/BaseButton'

const StyledField = styled(Field)`
  margin-bottom: 30px;
  margin-top: 5px;
`

const StyledHeader = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  margin: 20px 0 0 0;
`

interface ProfileFormProps {
  onSubmit: (arg0: ID, arg1: BasicUser) => Promise<void>
}

const AccountProfileForm: React.FC<ProfileFormProps & FormikProps<UserProfile>> = ({
  values,
  submitForm,
  setValues,
  setFieldValue,
}) => {
  const { userProfileStore, resetStores } = useStores()

  const handleAccountDeletion = () => {
    userProfileStore.dispatchDeleteAccount().then(() => {
      resetStores()
      dismissAccountDeleteModal()
    })
  }

  const [presentAccountDeleteModal, dismissAccountDeleteModal] = useIonModal(ActionModal, {
    icon: personOutline,
    title: `Delete account`,
    subtext:
      'Caution: This is a permanent action. This will delete all information associated to your account including jobsites and history. Are you sure?',
    submitText: 'Delete Account',
    onDismiss: () => dismissAccountDeleteModal(),
    onSubmit: handleAccountDeletion,
  })

  useEffect(() => {
    const userProfile = userProfileStore.profile
    setValues({ ...values, ...userProfile })
    setFieldValue('market_id', userProfile?.market.id)
    const strippedPhone = userProfile?.phone.replace('+1', '')
    setFieldValue('phone', formatPhoneNumber(strippedPhone))
  }, [userProfileStore.profile])

  return (
    <>
      <StyledField
        label="First name"
        name="first_name"
        placeholder="First name"
        component={TextInputField}
        type="text"
      />

      <StyledField label="Last name" name="last_name" placeholder="Last name" component={TextInputField} type="text" />

      <StyledField label="Email address" name="email" placeholder="Email" component={TextInputField} type="email" />

      <StyledField
        label="Phone number"
        name="phone"
        placeholder="(xxx) xxx-xxxx"
        component={TextInputField}
        type="tel"
        mask="(999)-999-9999"
      />

      <StyledField
        label="Company name"
        name="company_name"
        placeholder="Your company name"
        component={TextInputField}
        type="text"
      />

      <HorizontalRule />
      <StyledHeader>Danger zone:</StyledHeader>
      <BaseButton color="danger" fill="clear" expand="block" size="large" onClick={() => presentAccountDeleteModal()}>
        <h2>Delete my account</h2>
      </BaseButton>

      <Spacer height={100} />
      <SubmitButton onClick={submitForm}>Save Changes</SubmitButton>
    </>
  )
}

export default withFormik<ProfileFormProps, UserProfile>({
  displayName: 'AccountProfileForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    market_id: Yup.number().required('Select your market from the dropdown list'),
    first_name: Yup.string().required('Enter at least one character'),
    last_name: Yup.string().required('Enter at least one character'),
    email: Yup.string()
      .email('Enter a valid email address in the format name@example.com')
      .required('Enter a valid email address in the format name@example.com'),
    phone: Yup.string()
      .matches(/\(\d{3}\)-\d{3}-\d{4}/, 'Enter a 10 digit phone number')
      .required('Enter a 10 digit phone number'),
    company_name: Yup.string().required('Enter at least one character'),
  }),

  handleSubmit(values: UserProfile, { props: { onSubmit }, setErrors }: FormikBag<ProfileFormProps, UserProfile>) {
    const userValues = pick(values, USER_EDITABLE_ATTRS) as BasicUser
    catchErrors(
      () => onSubmit(values.id, userValues),
      ({ email, phone }) => {
        const errors = { email: '', phone: '' }
        if (email) {
          errors.email = 'This email is already taken'
        }
        if (phone) {
          if (phone[0] === 'is invalid') {
            errors.phone = 'This phone number is not valid'
          } else {
            errors.phone = 'This phone number is already taken'
          }
        }
        setErrors(errors)
      }
    )
  },
})(AccountProfileForm)
