import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import SunkenBorder from '@supplyhound/components/common/SunkenBorder'
import CheckableItemList from '@supplyhound/components/CheckableItemList'
import {
  AbstractItem,
  ID,
  MaterialListHeader,
  OptionallyIdentifiedOrdererListItem,
  OrdererListItem,
  SelectableOrdererListItem,
  TeamMember,
} from '@supplyhound/types'
import styled from 'styled-components'
import JobSiteDropdown from '@supplyhound/components/JobSiteDropdown'
import TeamMemberHeader from '@supplyhound/components/TeamMemberHeader'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import useStores from '@supplyhound/hooks/useStores'
import useModallyEditableItems from '@supplyhound/hooks/useModallyEditableItems'
import { OrdererListItemModalForm, FormModes } from '@supplyhound/white-label-components'
import { HeightState } from '@supplyhound/hooks/useDynamicHeight'
import { IonButton, useIonModal } from '@ionic/react'
import ActionModal from '@supplyhound/components/common/Modals/ActionModal'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'

const StyledJobSiteDropdown = styled(JobSiteDropdown)`
  width: 100%;
`

const StyledSunkenBorder = styled(SunkenBorder)`
  background: var(--ion-color-light);
`

const RequestChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  background-color: var(--ion-color-tertiary);
  border-top: 1px solid var(--greyscale-3);
  border-radius: 0 0 var(--ion-border-radius) var(--ion-border-radius);
`

const StyledIonButton = styled(IonButton)`
  margin: 0;
  height: 100%;
  color: var(--perma-dark-color);
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`

const StyledSubmit = styled(SubmitButton)`
  flex: 50%;
`

interface MaterialsListProps {
  jobSiteId: ID
  items: SelectableOrdererListItem[] | OrdererListItem[]
  header?: string
  editableJobSite?: boolean
  onCheckboxClick?: (item: AbstractItem & { id: ID; selected: boolean }, index: number) => any
  onCheckboxControlClick?: (isCheckboxOn: boolean) => any
  displayOnly?: boolean
  teamMember?: TeamMember
  canRequestChange?: boolean
  supplier: {
    logo: any
    name: string
    number: string
  }
}

const MaterialsList: React.FC<MaterialsListProps> = ({
  jobSiteId,
  items,
  header = MaterialListHeader.JobSiteDropdown,
  editableJobSite = false,
  onCheckboxClick,
  onCheckboxControlClick,
  displayOnly = false,
  teamMember,
  canRequestChange = false,
  supplier,
  ...props
}) => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const location = useLocation()
  const [showItems, setShowItems] = useState(true)

  const {
    jobSitesStore,
    jobSitesStore: { listStore },
  } = useStores()
  const jobSite = jobSitesStore.jobSites[jobSiteId]

  const goToDetail = (id: ID) => {
    loadWhile(async () =>
      // @ts-ignore see thread https://github.com/supplyhound/order-app/pull/137/files#r789259791
      history.push(generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id }))
    )
  }

  const handleJobSiteSelect = (id: ID) => id !== jobSiteId && goToDetail(id)

  const { handleEditItem, handleEditItemSubmit, handleEditItemCancel, itemInEdit, showModal } =
    useModallyEditableItems<OptionallyIdentifiedOrdererListItem>({
      items,
      onSubmit: item => loadWhile(() => listStore.dispatchUpdateItem(item)),
    })

  const [editedItem, setEditedItem] = useState<SelectableOrdererListItem>()

  const [presentChangeModal, dismissChangeModal] = useIonModal(ActionModal, {
    icon: supplier.logo,
    iconColor: 'dark',
    title: `Contact ${supplier.name}`,
    subtext: 'Call to request changes for your materials order.',
    onDismiss: () => dismissChangeModal(),
    showCancel: false,
    ActionButtons: (
      <StyledContainer>
        <StyledSubmit onClick={() => window.open(`tel:${supplier.number}`, '_self')}>Call</StyledSubmit>
      </StyledContainer>
    ),
  })

  useEffect(() => {
    // Prevent unnecessary re-fetching
    if (!displayOnly && !jobSite) {
      loadWhile(jobSitesStore.dispatchFetchJobSites)
    }
  }, [displayOnly, jobSite])

  return (
    <>
      <StyledSunkenBorder {...props}>
        {header === MaterialListHeader.JobSiteDropdown && (
          <StyledJobSiteDropdown
            selectedJobSite={jobSite}
            jobSites={jobSitesStore.jobSiteArray}
            onJobSiteSelect={handleJobSiteSelect}
            editableJobSite={editableJobSite}
            onDropdownStateChange={(state: HeightState) => {
              if (state === 'Expanding' || state === 'Expanded') {
                setShowItems(false)
              } else {
                setShowItems(true)
              }
            }}
          />
        )}
        {header === MaterialListHeader.TeamMember && <TeamMemberHeader teamMember={teamMember} />}
        {showItems && (
          <CheckableItemList<SelectableOrdererListItem>
            key={
              // including the pathname in the key forces the
              // component to remount whenever the view enters.
              // this ensures that its checkable state reflects
              // any changes made on another page (e.g. JobSiteTaskEditPage)
              location.pathname
            }
            items={items}
            onItemClick={handleEditItem}
            onCheckboxClick={onCheckboxClick}
            onCheckboxControlClick={onCheckboxControlClick}
            displayOnly={displayOnly}
            editedItem={editedItem}
          />
        )}
        {canRequestChange && (
          <RequestChangeContainer>
            <StyledIonButton size="small" expand="block" fill="clear" onClick={() => presentChangeModal()}>
              Request a change
            </StyledIonButton>
          </RequestChangeContainer>
        )}
      </StyledSunkenBorder>
      <OrdererListItemModalForm
        isOpen={showModal}
        item={itemInEdit}
        onSubmit={updatedItem => {
          return handleEditItemSubmit(updatedItem).then(() => {
            setEditedItem(updatedItem as SelectableOrdererListItem)
          })
        }}
        onCancel={handleEditItemCancel}
        mode={FormModes.Edit}
        headerSubtitle={jobSite?.name}
      />
    </>
  )
}

export default observer(MaterialsList)
