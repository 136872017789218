import React, { useEffect } from 'react'
import { IonPage } from '@ionic/react'
import styled from 'styled-components'

import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'

import Content from '@supplyhound/components/Content'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { useHistory, useParams } from 'react-router-dom'
import { JobSiteDetailRouteParams, TaskTypes, UnidentifiedDeliveryTask } from '@supplyhound/types'

import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { JOB_SITE_ORDER_RECEIPT_ROUTE } from '@supplyhound/utils/config'
import { Header, HeaderModes } from '@supplyhound/layout'

import MapPin from '@supplyhound/components/MapPin'
import { OrderDetails } from '@supplyhound/white-label-components'
import { GoogleMap } from '@react-google-maps/api'
import DirectionsMap from '@supplyhound/components/DirectionsMap'
import { GoogleMapStyles } from '@supplyhound/styles'
import Spacer from '@supplyhound/components/common/Spacer'

import { DeliveryTypeConfigs } from '@supplyhound/utils/datetimes'

const ButtonContainer = styled.div`
  padding: 5px 15px 0px 15px;
  margin-bottom: 25px;
`

const JobSiteTaskDetailPage: React.FC = () => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const {
    jobSitesStore,
    jobSitesStore: { listStore },
    userProfileStore,
  } = useStores()

  const waiveOrderMangementFee = true

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const jobSite =
    jobSiteId === jobSitesStore.deliveryOnlyJobSiteId
      ? jobSitesStore.deliveryOnlyJobSite
      : jobSitesStore.jobSites[jobSiteId]

  const billingDetails = jobSitesStore.getJobSiteTaskBillingDetails(jobSiteId, waiveOrderMangementFee, false)
  const task = jobSitesStore.getJobSiteTask(jobSiteId) as UnidentifiedDeliveryTask
  const isDelivery = task?.type === TaskTypes.Delivery

  const directionsCallback = (distance: number) => {
    jobSitesStore.updateJobSiteTask(jobSiteId, { job_distance: distance })
  }
  const marketTimeZone = userProfileStore.marketTimezone
  const updateTimes = () => {
    if (task && isDelivery) {
      const deliveryType = task.delivery_type

      if (!DeliveryTypeConfigs[deliveryType]) return

      const [updatedPickup, updatedDelivery] = DeliveryTypeConfigs[deliveryType].computeDatetimes(
        marketTimeZone,
        task.delivery_datetime
      )
      jobSitesStore.updateJobSiteTask(jobSiteId, {
        pickup_datetime: updatedPickup,
        delivery_datetime: updatedDelivery,
      })
    }
  }

  const handleSubmitClick = () => {
    updateTimes()
    loadWhile(() => {
      return isDelivery
        ? jobSitesStore.dispatchCreateJobSiteDeliveryTask(jobSiteId, '', waiveOrderMangementFee, false)
        : jobSitesStore.dispatchCreateJobSitePickupTask(jobSiteId, '', waiveOrderMangementFee, false)
    }).then(() => {
      history.push(JOB_SITE_ORDER_RECEIPT_ROUTE)
    })
  }

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)

    updateTimes()

    const timer: ReturnType<typeof setTimeout> = setInterval(() => {
      updateTimes()
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  }, [task])

  useEffect(() => {
    if (jobSite && !task?.ordered_directly) {
      listStore.dispatchFetchList(jobSite.orderer_list.id)
    }
  }, [jobSite, task?.ordered_directly])

  useEffect(() => {
    jobSitesStore.updateJobSiteTask(jobSiteId, {
      ordered_with_subscription: waiveOrderMangementFee,
    })
  }, [])

  return (
    <IonPage>
      <Header label="Review" mode={HeaderModes.Leaf} />
      <ButtonContainer>
        <SubmitButton expand="block" size="large" onClick={handleSubmitClick}>
          Place Order
        </SubmitButton>
      </ButtonContainer>
      <Content>
        <OrderDetails task={task} billingDetails={billingDetails} />

        <Spacer height={25} />
        {task && isDelivery ? (
          <GoogleMap options={{ gestureHandling: 'none' }} mapContainerStyle={GoogleMapStyles}>
            <DirectionsMap
              origin={task.pickup_address}
              destination={(task as UnidentifiedDeliveryTask).delivery_address}
              travelMode={google.maps.TravelMode.DRIVING}
              callback={directionsCallback}
            />
          </GoogleMap>
        ) : (
          <MapPin address={task?.pickup_address} />
        )}
      </Content>
    </IonPage>
  )
}

export default observer(JobSiteTaskDetailPage)
