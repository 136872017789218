import React, { useEffect } from 'react'
import JobSitesFilter from '@supplyhound/components/JobSitesFilter'
import { fetchOrderHistory } from '@supplyhound/api'
import { sortTasksByStatus } from '@supplyhound/utils/history'

import styled from 'styled-components'
import { IonModal, IonSpinner } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'

import { HistoryTask } from '@supplyhound/types'
import TaskCard from '@supplyhound/components/TaskCard'
import Spacer from '@supplyhound/components/common/Spacer'
import { readerOutline } from 'ionicons/icons'
import { TaskSummaryModal } from '@supplyhound/white-label-components'
import { SUPPLIER_INFO } from 'config'
import _ from 'lodash'

const FlexContainer = styled.div`
  display: flex;
`

const LoadingContainer = styled(FlexContainer)`
  height: 100px;
  justify-content: center;
  margin-top: 100px;
  align-items: center;
`

const Spinner = styled(IonSpinner)`
  transform: scale(3);
`

const SectionLabel = styled.div`
  margin-top: calc(2 * var(--space-unit));
  font-weight: 500;
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 50px;
  text-align: center;
`

const FullScreenModal = styled(IonModal)`
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  @media (min-width: 1024px) {
    --min-width: 1024px;
    --max-width: 1024px;
  }
`

type HistoryItemsProps = {
  jobSiteId?: number
}

const HistoryOrders = (props: HistoryItemsProps = {}) => {
  const [selectedJobsiteId, setSelectedJobsiteId] = React.useState<number | undefined>(props.jobSiteId || undefined)

  const [orders, setOrders] = React.useState<{
    activeOrders: HistoryTask[]
    completedOrders: HistoryTask[]
  }>({ activeOrders: [], completedOrders: [] })

  const activeOrders = orders.activeOrders
  const completedOrders = orders.completedOrders

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    setOrders({ activeOrders: [], completedOrders: [] })
    fetchOrderHistory(selectedJobsiteId).then(({ tasks }: { tasks: HistoryTask[] }) => {
      const [activeOrders, completedOrders] = sortTasksByStatus(tasks)
      setOrders({ completedOrders, activeOrders })
      setIsLoading(false)
    })
  }, [selectedJobsiteId])

  const isEmpty = activeOrders.length === 0 && completedOrders.length === 0
  const [task, setTask] = React.useState<HistoryTask | undefined>()

  return (
    <div>
      {!props.jobSiteId && <JobSitesFilter onChange={siteId => setSelectedJobsiteId(siteId)} />}
      {isLoading && (
        <LoadingContainer>
          <Spinner name="circles" />
        </LoadingContainer>
      )}
      {activeOrders.length > 0 && <SectionLabel>Active Orders</SectionLabel>}
      {activeOrders.map(order => {
        return (
          <TaskCard
            task={order}
            key={order.id}
            onClick={() => {
              setTask(order)
              setShowModal(true)
            }}
          />
        )
      })}
      {completedOrders.length > 0 && <SectionLabel>Completed Orders</SectionLabel>}
      {completedOrders.map(order => {
        return (
          <TaskCard
            task={order}
            key={order.id}
            onClick={() => {
              setTask(order)
              setShowModal(true)
            }}
          />
        )
      })}
      {!isLoading && isEmpty && (
        <EmptyContainer>
          <Icon icon={readerOutline} size="large" width={50} />
          <Spacer height={30} />
          <div>All orders through SupplyHound will appear here.</div>
        </EmptyContainer>
      )}
      <FullScreenModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <TaskSummaryModal
          task={_.omit(task!, ['billingDetails'])}
          setShowModal={setShowModal}
          supplierInfo={SUPPLIER_INFO}
        />
      </FullScreenModal>
    </div>
  )
}

export default HistoryOrders
