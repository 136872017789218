import React from 'react'
import styled from 'styled-components'
import {
  IonContent,
  IonFooter,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonRouterLink,
  IonText,
} from '@ionic/react'
import { generatePath, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { bookmarkOutline, createOutline, locationOutline, logOutOutline, personOutline } from 'ionicons/icons'
import { Help, History, Team } from '@supplyhound/icons'

import {
  HOME_ROUTE,
  ACCOUNT_ROUTE,
  DELIVERY_ONLY_ADD_SUPPLIER_ROUTE,
  HELP_ROUTE,
  HISTORY_ROUTE,
  JOB_SITE_LIST_SELECT_ROUTE,
  MANAGE_JOB_SITES_SELECT_ROUTE,
  TEAM_MEMBERS_ROUTE,
} from '@supplyhound/utils/config'
import useStores from '@supplyhound/hooks/useStores'
import { MenuItem } from '@supplyhound/types'
import Icon from '@supplyhound/components/common/Icon'
import ListItem from '@supplyhound/components/common/ListItem'

const SidePadding = 'calc(var(--space-unit) * 2)'

const StyledMenuButton = styled(IonMenuButton)`
  background: var(--ion-color-dark);
  color: var(--ion-color-secondary);
  border: 1.5px solid;
  border-radius: var(--ion-border-radius);
  width: 50px;
  height: 50px;
  border: 1.5px solid;
`

const StyledNameText = styled.div`
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledList = styled(IonList)`
  padding: 0;
`

const StyledListHeader = styled(IonListHeader)`
  padding: calc(var(--space-unit) * 3) ${SidePadding};
`

const HeaderInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Footer = styled(IonFooter)`
  padding: calc(var(--space-unit) * 2) ${SidePadding};
`

const Label = styled(IonLabel)`
  &&& {
    font-size: 1.33rem;
  }
  margin: 0;
`

const ListMenuItem = styled(ListItem)`
  &::part(native) {
    padding-left: ${SidePadding};
  }
`

const OrdererSidebar: React.FC = () => {
  const { userProfileStore, resetStores } = useStores()
  const userName = userProfileStore.profile?.first_name || 'User'
  const location = useLocation()

  const menuItems: MenuItem[] = [
    {
      title: 'Home',
      icon: bookmarkOutline,
      routeProps: {
        routerLink: HOME_ROUTE,
      },
    },

    {
      title: 'Jobsites',
      icon: locationOutline,
      routeProps: {
        routerLink: MANAGE_JOB_SITES_SELECT_ROUTE,
      },
    },

    {
      title: 'Account',
      icon: personOutline,
      routeProps: {
        routerLink: ACCOUNT_ROUTE,
      },
    },
    {
      title: 'Orders',
      icon: History,
      routeProps: {
        routerLink: HISTORY_ROUTE,
      },
    },

    {
      title: 'Logout',
      icon: logOutOutline,
      routeProps: {
        onClick: () => {
          resetStores()
        },
        routerLink: '/logout',
      },
    },
  ]

  return (
    <IonMenu contentId="main" menuId="menu" type="overlay">
      <IonContent>
        <StyledList>
          <StyledListHeader lines="full">
            <HeaderInnerWrapper>
              <StyledMenuButton />
              <StyledNameText>
                <IonText>{userName}</IonText>
              </StyledNameText>
            </HeaderInnerWrapper>
          </StyledListHeader>

          {menuItems.map(({ title, routeProps, icon }, index) => (
            <IonMenuToggle key={index}>
              <ListMenuItem lines="full" detail={true} {...routeProps}>
                <Icon
                  slot="start"
                  icon={icon}
                  invertColors={!!routeProps.routerLink && location.pathname === routeProps.routerLink}
                  border
                  width={40}
                  containerPadding={8}
                />
                <Label>{title}</Label>
              </ListMenuItem>
            </IonMenuToggle>
          ))}
        </StyledList>
        <Footer className="ion-no-border"></Footer>
      </IonContent>
    </IonMenu>
  )
}

export default observer(OrdererSidebar)
