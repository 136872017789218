import React from 'react'
import BaseModal from '@supplyhound/components/common/Modals/BaseModal'

import styled from 'styled-components'
import useStores from '@supplyhound/hooks/useStores'

const FieldSection = styled.div`
  padding-bottom: 20px;
`

const FieldHeader = styled.h2`
  padding-bottom: 10px;
`

const FieldExplanation = styled.div`
  color: var(--greyscale-7);
`

const DeliveryFormHelpModal = ({
  dismiss,
  isDeliveryOnly,
  isWillCall,
}: {
  dismiss: () => void
  isDeliveryOnly?: boolean
  isWillCall?: boolean
}) => {
  const { userProfileStore } = useStores()

  const supplierDeliveryFeatureFlag = userProfileStore.profile?.feature_flags.supplier_delivery

  return (
    <BaseModal label={'Information'} onDidDismiss={() => dismiss()}>
      {!isDeliveryOnly && (
        <FieldSection>
          <FieldHeader>Delivery or Will Call</FieldHeader>
          <FieldExplanation>
            Use the toggle to have your order delivered onsite or schedule a time to pickup yourself.
          </FieldExplanation>
        </FieldSection>
      )}
      {!isWillCall && (
        <>
          <FieldSection>
            <FieldHeader>Delivery to</FieldHeader>
            <FieldExplanation>
              For deliveries, deliver to is the address where you want supplies delivered.
            </FieldExplanation>
          </FieldSection>

          <FieldSection>
            <FieldHeader>Deliver date</FieldHeader>
            <FieldExplanation>
              For deliveries, deliver by is the time you want the supplies delivered. Expedited deliveries have
              additional fees displayed.
            </FieldExplanation>
          </FieldSection>
          <FieldSection>
            <FieldHeader>Delivery with</FieldHeader>
            <FieldExplanation>
              For deliveries, select truck or car. Truck max payload is 1800 lbs, car items are less than 50 lbs and max
              4 ft. long.
            </FieldExplanation>
          </FieldSection>
        </>
      )}

      {isDeliveryOnly && (
        <FieldSection>
          <FieldHeader>PO#</FieldHeader>
          <FieldExplanation>
            If you have an order for pickup, enter your purchase order number from your supplier.
          </FieldExplanation>
        </FieldSection>
      )}
      {!isDeliveryOnly && isWillCall && (
        <FieldSection>
          <FieldHeader>Pickup by</FieldHeader>
          <FieldExplanation>
            For Will Call, pickup by is the time you want the supplies ready to be picked up. We will notify you when
            your order is ready.
          </FieldExplanation>
        </FieldSection>
      )}
      <FieldSection>
        <FieldHeader>Note</FieldHeader>
        <FieldExplanation>
          Add special instructions or any information that can be helpful with your order. The note will be seen by
          SupplyHound, the driver and the supplier.
        </FieldExplanation>
      </FieldSection>
    </BaseModal>
  )
}

export default DeliveryFormHelpModal
