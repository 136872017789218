import Icon from '@supplyhound/components/common/Icon'
import Caption from '@supplyhound/components/common/text/Caption'
import React from 'react'
import styled from 'styled-components'
import Logo from '@supplyhound/images/sh-logo-icon.svg'
import Spacer from '@supplyhound/components/common/Spacer'

const Container = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: var(--ion-text-color);
`

const Align = styled.div`
  display: flex;
  align-items: center;
`

const Footer = () => {
  return (
    <Container>
      <Caption>
        <Align>
          <Icon icon={Logo} />
          <Spacer width={5} />
          <span>Powered by SupplyHound</span>
        </Align>
      </Caption>
    </Container>
  )
}

export default Footer
