import React from 'react'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import { User, SelectOption } from '@supplyhound/types'
import useHandlePressEnter from '@supplyhound/hooks/useHandlePressEnter'
import * as Yup from 'yup'
import styled from 'styled-components'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { catchErrors } from '@supplyhound/utils/forms'

export interface SignUpFormValues extends User {
  agree_terms: boolean
}

interface SignUpFormProps {
  marketOptions: SelectOption[]
  onSubmit: (arg0: SignUpFormValues) => Promise<void>
}

const ButtonTier = styled.div`
  padding-bottom: calc(2 * var(--space-unit));
`

const StyledField = styled(Field)`
  margin-bottom: 30px;
  margin-top: 5px;
`

const SignUpForm: React.FC<SignUpFormProps & FormikProps<SignUpFormValues>> = ({ marketOptions, submitForm }) => {
  useHandlePressEnter(submitForm, false)

  return (
    <>
      <StyledField
        label="First name"
        name="first_name"
        placeholder="First name"
        component={TextInputField}
        type="text"
      />

      <StyledField label="Last name" name="last_name" placeholder="Last name" component={TextInputField} type="text" />

      <StyledField label="Email address" name="email" placeholder="Email" component={TextInputField} type="email" />

      <StyledField
        label="Phone number"
        name="phone"
        placeholder="(xxx) xxx-xxxx"
        component={TextInputField}
        type="tel"
        mask="(999)-999-9999"
      />

      <StyledField
        label="Company name"
        name="company_name"
        placeholder="Your company name"
        component={TextInputField}
        type="text"
      />

      <StyledField
        label="Create password"
        name="password"
        placeholder="Password"
        component={TextInputField}
        type="password"
      />
      <ButtonTier>
        <SubmitButton color="dark" onClick={submitForm}>
          Create account
        </SubmitButton>
      </ButtonTier>
    </>
  )
}

export default withFormik<SignUpFormProps, SignUpFormValues>({
  displayName: 'SignUpForm',
  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    first_name: Yup.string().required('Enter at least one character'),
    last_name: Yup.string().required('Enter at least one character'),
    email: Yup.string()
      .email('Enter a valid email address in the format name@example.com')
      .required('Enter a valid email address in the format name@example.com'),
    phone: Yup.string()
      .matches(/\(\d{3}\)-\d{3}-\d{4}/, 'Enter a 10 digit phone number')
      .required('Enter a 10 digit phone number'),
    company_name: Yup.string().required('Enter at least one character'),
    password: Yup.string().required('Required'),
  }),

  handleSubmit(
    values: SignUpFormValues,
    { props: { onSubmit }, setErrors }: FormikBag<SignUpFormProps, SignUpFormValues>
  ) {
    // Accounting email need to be converted to an array for submission
    let acct_emails: string[] = []
    values.accounting_emails = acct_emails.concat(values.accounting_emails)

    catchErrors(
      () => onSubmit(values),
      ({ email, phone }) => {
        const errors = { email: '', phone: '' }
        if (email) {
          errors.email = 'This email is already taken'
        }
        if (phone) {
          if (phone[0] === 'is invalid') {
            errors.phone = 'This phone number is not valid'
          } else {
            errors.phone = 'This phone number is already taken'
          }
        }
        setErrors(errors)
      }
    )
  },
})(SignUpForm)
