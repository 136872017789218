import React, { useEffect } from 'react'
import styled from 'styled-components'
import { generatePath, useHistory, useParams, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { IonLabel } from '@ionic/react'
import Content from '@supplyhound/components/Content'
import { MaterialsList } from '@supplyhound/white-label-components'
import AddButton from '@supplyhound/components/buttons/AddButton'

import {
  JobSiteDetailRouteParams,
  OptionallyIdentifiedOrdererListItem,
  OrdererListItem,
  UnidentifiedOrdererListItem,
  AbstractItem,
  ID,
} from '@supplyhound/types'
import useStores from '@supplyhound/hooks/useStores'
import useModallyEditableItems from '@supplyhound/hooks/useModallyEditableItems'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { OrdererListItemModalForm, FormModes } from '@supplyhound/white-label-components'
import Card from '@supplyhound/components/common/Card'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { HISTORY_ADD_TO_JOBSITE_ROUTE, JOB_SITE_TASK_ADD_SUPPLIER_ROUTE } from '@supplyhound/utils/config'
import { modalController } from '@ionic/core'
import { ALL_SHORTCUTS } from '@supplyhound/forms/shortcuts'

const OrderContainer = styled.div`
  padding: var(--space-unit) calc(2 * var(--space-unit)) calc(2 * var(--space-unit)) calc(2 * var(--space-unit));
  background: var(--ion-background-color);
`
const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: calc(2 * var(--space-unit));
`
const StyledCard = styled(Card)`
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 500;
  color: var(--ion-text-color);
`

const OrdererList: React.FC<{ shortcuts?: string[]; supplierInfo: { name: string; logo: any; number: string } }> = ({
  shortcuts = ALL_SHORTCUTS,
  supplierInfo,
}) => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const location = useLocation()

  const {
    jobSitesStore,
    jobSitesStore: { listStore },
  } = useStores()
  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const jobSite = jobSitesStore.jobSites[jobSiteId]

  const list = jobSitesStore.getJobSiteOrdererList(jobSiteId)
  let items = list?.active_items ? list.active_items : []
  let selectedItems = jobSitesStore.taskStore.getTask(jobSiteId).itemIds

  const handleAddOrEditItemSubmit = (item: OptionallyIdentifiedOrdererListItem) => {
    if (!list) throw new Error("Can't edit item without a list!")

    return loadWhile(async () => {
      if (item.id) {
        await listStore.dispatchUpdateItem(item as OrdererListItem)
      } else {
        const newItem = await listStore.dispatchCreateItem(list.id, item as UnidentifiedOrdererListItem)
        await jobSitesStore.setJobSiteTaskItemSelectState(jobSiteId, newItem.id!, true)
      }
    })
  }

  const { handleAddItem, handleEditItemSubmit, handleEditItemCancel, showModal } =
    useModallyEditableItems<OptionallyIdentifiedOrdererListItem>({
      items,
      onSubmit: handleAddOrEditItemSubmit,
    })

  const handleStartOrderButtonClick = () => {
    history.push(generatePath(JOB_SITE_TASK_ADD_SUPPLIER_ROUTE, { id }))
  }
  useEffect(() => {
    if (jobSite) {
      listStore.dispatchFetchList(jobSite.orderer_list.id)
    }
  }, [location.key, jobSite])

  return (
    <>
      <Content>
        <MaterialsList
          jobSiteId={jobSiteId}
          items={toJS(items)}
          editableJobSite
          onCheckboxClick={(item: AbstractItem & { id: ID; selected: boolean }) => {
            jobSitesStore.setJobSiteTaskItemSelectState(jobSiteId, item.id, item.selected)
          }}
          onCheckboxControlClick={isCheckboxOn => {
            items.forEach(item => {
              jobSitesStore.setJobSiteTaskItemSelectState(jobSiteId, item.id, isCheckboxOn)
            })
          }}
          supplier={supplierInfo}
        />
        {list && (
          <ActionRow>
            <AddButton onClick={handleAddItem}>
              <IonLabel>Add item</IonLabel>
            </AddButton>
          </ActionRow>
        )}
      </Content>
      <OrderContainer>
        {selectedItems.length > 0 ? (
          <SubmitButton onClick={handleStartOrderButtonClick}>START ORDER</SubmitButton>
        ) : (
          <StyledCard>Check items to start order</StyledCard>
        )}
      </OrderContainer>
      {jobSite && (
        <OrdererListItemModalForm
          isOpen={showModal}
          onSubmit={handleEditItemSubmit}
          onCancel={handleEditItemCancel}
          mode={FormModes.Add}
          headerSubtitle={jobSite?.name}
          onAddFromHistoryClick={async () => {
            await modalController.dismiss()
            history.push(generatePath(HISTORY_ADD_TO_JOBSITE_ROUTE, { jobSiteId: jobSiteId }))
          }}
          shortcuts={shortcuts}
        />
      )}
    </>
  )
}

export default observer(OrdererList)
