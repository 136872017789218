import React from 'react'
import styled from 'styled-components'

import { personOutline, logOutOutline } from 'ionicons/icons'

import { useHistory, useLocation } from 'react-router-dom'
import Icon from '@supplyhound/components/common/Icon'
import { IonPopover } from '@ionic/react'
import { popoverController } from '@ionic/core'

import useStores from '@supplyhound/hooks/useStores'
import { observer } from 'mobx-react-lite'
import Spacer from '@supplyhound/components/common/Spacer'

import { getMenuItems } from './configs'
import { TEAM_MEMBER_LOGIN_ROUTE } from '@supplyhound/utils/config'

const NavContainer = styled.div`
  width: 100%;
  background-color: var(--greyscale-1);
  border-bottom: 1px solid var(--greyscale-3);
  padding: 20px 40px;
  display: flex;
  justify-content: center;
`

const NavMaxWidth = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const LogoContainer = styled.div`
  cursor: pointer;
  width: 200px;
  min-width: 200px;
`
const LogoImg = styled.img<{ logo: any }>`
  content: url(${({ logo }) => logo});
`

const NavItem = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px 15px;
  margin: 0px 20px;
  border-radius: 90px;
  color: #000;
  ${({ isSelected }) => {
    if (isSelected) {
      return `
      color: var(--perma-dark-color);
      background-color: var(--ion-color-primary)
      `
    }
    return ``
  }};
`

const DropdownContainer = styled(FlexContainer)`
  cursor: pointer;
  color: #000;
`

const StyledPopover = styled(IonPopover)`
  --min-width: 250px;
`

const PopOverContent = styled.div``
const Username = styled.div`
  padding: 40px 20px 40px 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--greyscale-3);
`
const DropdownItem = styled(FlexContainer)`
  padding: 20px 20px;
  cursor: pointer;
  &:hover {
    background-color: var(--greyscale-3);
  }
`

type DesktopNavType = {
  type: 'orderer' | 'teammember' | 'whitelabel' | 'none'
  logo: any
  homeLink: string
}

const DesktopNav: React.FC<DesktopNavType> = ({ type, logo, homeLink }) => {
  const { pathname } = useLocation()
  const { userProfileStore, resetStores, teamMemberProfileStore } = useStores()
  const history = useHistory()

  const userName = userProfileStore.profile?.first_name || teamMemberProfileStore.profile?.first_name || 'User'

  const [menuItems, dropdownItems] = getMenuItems(type)

  return (
    <NavContainer>
      <NavMaxWidth>
        <a href={homeLink}>
          <LogoContainer>
            <LogoImg logo={logo} alt="Home" />
          </LogoContainer>
        </a>
        <FlexContainer>
          {menuItems.map(item => {
            const isSelected = item.routeProps.routeMatcher.test(pathname)
            return (
              <NavItem
                key={item.title}
                isSelected={isSelected}
                onClick={() => history.push(item.routeProps.routerLink)}
              >
                <div>{item.title}</div>
              </NavItem>
            )
          })}
          <DropdownContainer>
            <Icon icon={personOutline} width={30} id="menu-account" />
            <StyledPopover trigger="menu-account" triggerAction="click">
              <PopOverContent>
                <Username>
                  <h1>{userName}</h1>
                </Username>
                {dropdownItems.map(item => {
                  return (
                    <DropdownItem
                      key={item.title}
                      onClick={() => {
                        popoverController.dismiss()
                        history.push(item.routeProps.routerLink)
                      }}
                    >
                      <Icon icon={item.icon} width={30} />
                      <Spacer width={10} />
                      {item.title}
                    </DropdownItem>
                  )
                })}
                <DropdownItem
                  onClick={() => {
                    popoverController.dismiss()
                    resetStores()
                    history.push(type === 'teammember' ? TEAM_MEMBER_LOGIN_ROUTE : '/logout')
                  }}
                >
                  <Icon icon={logOutOutline} width={30} />
                  <Spacer width={10} />
                  Logout
                </DropdownItem>
              </PopOverContent>
            </StyledPopover>
          </DropdownContainer>
        </FlexContainer>
      </NavMaxWidth>
    </NavContainer>
  )
}

export default observer(DesktopNav)
