import React, { useEffect } from 'react'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { JobSiteDetailRouteParams } from '@supplyhound/types'
import Content from '@supplyhound/components/Content'
import { SupplierSearchFormValues } from '@supplyhound/forms/SupplierSearchForm'
import { Header, HeaderModes } from '@supplyhound/layout'

import { useStores, useLoadingState, useGoogleGeocoder } from '@supplyhound/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { JOB_SITE_TASK_EDIT_ROUTE } from '@supplyhound/utils/config'
import { toJS } from 'mobx'

import { SupplierSelectionForm } from '@supplyhound/white-label-components'
interface JobSiteTaskAddSupplierPageProps {
  supplierLocations?: { name: string; address: string; displayText: string; place_id: string; supplier_id?: number }[]
}

const JobSiteTaskAddSupplierPage: React.FC<JobSiteTaskAddSupplierPageProps> = ({ supplierLocations }) => {
  const { loadWhile } = useLoadingState()
  const history = useHistory()
  const { jobSitesStore, suppliersStore } = useStores()
  const { getByPlaceId } = useGoogleGeocoder()

  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const task = jobSitesStore.getJobSiteTask(jobSiteId)

  const updateStores = ({ supplier }: SupplierSearchFormValues) =>
    loadWhile(async () => {
      if (!supplier.supplier_id && supplier.place_id) {
        supplier.supplier_id = await suppliersStore.dispatchFetchSupplierIdByPlaceId(supplier.place_id)
      }
      if (supplier.place_id) {
        const geocodeResult = await getByPlaceId(supplier.place_id)
        if (geocodeResult) {
          supplier.address = geocodeResult.formatted_address
        }
      }

      // We need to work with the task store directly because the job site isn't known at this point
      jobSitesStore.updateJobSiteTask(jobSiteId, {
        supplier,
        pickup_address: supplier.address,
      })
    })

  const handleSearchFormSubmit = ({ supplier }: SupplierSearchFormValues) => {
    updateStores({ supplier }).then(() => {
      history.push(generatePath(JOB_SITE_TASK_EDIT_ROUTE, { id }))
    })
  }

  useEffect(() => {
    if (supplierLocations && supplierLocations.length === 1) {
      const supplier = supplierLocations[0]
      updateStores({ supplier }).then(() => {
        history.replace(generatePath(JOB_SITE_TASK_EDIT_ROUTE, { id }))
      })
    }
  }, [])

  return (
    <IonPage>
      <Header mode={HeaderModes.Leaf} label={'Choose Supplier'} />
      <Content>
        <SupplierSelectionForm
          supplier={toJS(task?.supplier)}
          onSubmit={handleSearchFormSubmit}
          label="Supplier for this order"
          supplierLocations={supplierLocations}
        />
      </Content>
    </IonPage>
  )
}

export default observer(JobSiteTaskAddSupplierPage)
