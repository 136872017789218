export const SUPPLIER_ID = process.env.REACT_APP_SUPPLIER_ID
export const SUPPLIER_NAME = process.env.REACT_APP_SUPPLIER_NAME
export const LOGIN_ROUTE = '/login'

export const SUPPLIER_LOCATIONS = [
  {
    name: 'Local Lumber-Mountain View A',
    address: 'Local Lumber, 1600 Amphitheatre Pkwy, Mountain View, CA 94043',
    displayText: 'Local Lumber - Mountain View A',
    place_id: 'ChIJaeE8h3W6j4AR7as0vi4zwBw',
  },
  {
    name: 'Local Lumber-Mountain View B',
    address: 'Local Lumber, 1800 Local Lumber Pkwy, Mountain View, CA 94043',
    displayText: 'Local Lumber - Mountain View B',
    place_id: 'ChIJaeE8h3W6j4AR7as0vi4zwBw',
  },
]
export const SUPPLIER_INFO = {
  name: 'Local Lumber',
  number: '(415) 349-5085',
  logo: '',
}
