import React, { useEffect, useState } from 'react'
import { Field, FormikBag, FormikProps, withFormik } from 'formik'
import styled from 'styled-components'
import {
  SupplierDeliveryType,
  ID,
  Location,
  ForwardPropsToChildren,
  SelectableOrdererListItem,
  UnidentifiedDeliveryTask,
  DeliveryBy,
  SupplierDeliveryDate,
  VehicleType,
} from '@supplyhound/types'
import {
  getLabeledSupplierDeliveryTypeConfigs,
  SupplierDeliveryTypeConfigs,
  supplierDeliveryDateOptions,
  dateDisplayFormatter,
} from '@supplyhound/utils/datetimes'
import { compose } from 'lodash/fp'
import { observer } from 'mobx-react-lite'
import LocationSearchField from '@supplyhound/forms/fields/LocationSearchField'
import TextInputField from '@supplyhound/forms/fields/TextInputField'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import DatetimeModalField from '@supplyhound/forms/fields/DatetimeModalField'
import { DELIVERY_DETAILS_VALIDATION_SCHEMA } from '@supplyhound/utils/validation'
import { omit } from 'lodash'
import useStores from '@supplyhound/hooks/useStores'
import MaterialListField from '@supplyhound/forms/DetailsForm/MaterialListField'
import { DateTime } from 'luxon'
import PillSelectField from '@supplyhound/forms/fields/PillSelect'

export type DeliveryDetailsFormValues = Pick<
  UnidentifiedDeliveryTask,
  | 'job_site_id'
  | 'job_site_name'
  | 'delivery_type'
  | 'delivery_by'
  | 'vehicle_type'
  | 'delivery_address'
  | 'delivery_datetime'
  | 'delivery_username'
  | 'delivery_phone'
  | 'pickup_datetime'
  | 'delivery_note'
> & {
  location?: Partial<Location>
  taskItems: SelectableOrdererListItem[]
}

type DeliveryDetailsFormProps = {
  task: DeliveryDetailsFormValues
  onSubmit: (values: DeliveryDetailsFormValues) => void
  onChange: (values: DeliveryDetailsFormValues) => void
  taskItems: SelectableOrdererListItem[]
  jobSiteId: ID
}

type FormikValueProps = FormikProps<DeliveryDetailsFormValues>
type Props = ForwardPropsToChildren<DeliveryDetailsFormProps, FormikValueProps>

const Row = styled.div`
  padding-top: calc(2 * var(--space-unit));
`

const DeliveryTypeField = styled(Field)`
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 1);
  }
`

const DeliverWithField = styled(Field)`
  max-width: 45%;
`
const SupplierDeliveryField = styled(Field)`
  max-width: 45%;
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 5);
  }
`
const StyledLocationSearchField = styled(LocationSearchField)`
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 2);
  }
`

const SupplierDeliverDateField = styled(Field)`
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 4);
  }
`

const SupplierDeliverTimeField = styled(Field)`
  .expandable-list {
    z-index: calc(var(--dropdown-z-index) + 3);
  }
`

const DeliveryDetailsForm: React.FC<Props & FormikValueProps> = ({
  values,
  submitForm,
  setValues,
  setFieldValue,
  errors,
  jobSiteId,
  touched,
}) => {
  const { jobSitesStore, userProfileStore } = useStores()
  const marketTimeZone = userProfileStore.marketTimezone
  const task = jobSitesStore.getJobSiteTask(jobSiteId)
  const supplierDeliveryFeatureFlag = userProfileStore.profile?.feature_flags.supplier_delivery

  const [showCustomDeliveryDatetimeModal, setShowCustomDeliveryDatetimeModal] = useState<boolean>(false)

  const [supplierDeliveryDateNote, setSupplierDeliveryDateNote] = useState('')
  const [supplierDeliveryTypeNote, setSupplierDeliveryTypeNote] = useState('')

  useEffect(() => {
    if (values.delivery_datetime) {
      let datetime = DateTime.fromISO(values.delivery_datetime)
      const month = datetime.month
      const day = datetime.day
      setSupplierDeliveryDateNote(`${month}/${day}`)

      if (values.delivery_type) {
        switch (values.delivery_type) {
          case SupplierDeliveryType.Anytime:
            datetime = datetime.setZone(marketTimeZone).set({ hour: 23, minute: 59, second: 59 })
            break
          case SupplierDeliveryType.Morning:
            datetime = datetime.setZone(marketTimeZone).set({ hour: 12, minute: 0, second: 0 })
            break
          case SupplierDeliveryType.Afternoon:
            datetime = datetime.setZone(marketTimeZone).set({ hour: 16, minute: 30, second: 0 })
            break
        }
      }

      // Setting the pickup_datetime to be the same as delivery_datetime as it is a required field
      setValues({ ...values, delivery_datetime: datetime.toISO(), pickup_datetime: datetime.toISO() })
    }
  }, [values.delivery_datetime, values.delivery_type, values.delivery_by])

  //Update location value
  useEffect(() => {
    if (!values.location) return

    setFieldValue('delivery_address', values.location.full_address)
  }, [values.location])

  // updating Supplier delivery note
  useEffect(() => {
    if (values.delivery_by === DeliveryBy.Supplier) {
      setFieldValue(
        'delivery_note',
        `Supplier delivery requested - ${supplierDeliveryDateNote} ${supplierDeliveryTypeNote}`
      )
    }
  }, [supplierDeliveryDateNote, supplierDeliveryTypeNote, values.delivery_by])

  return (
    <div>
      <Row>
        <Field
          name="location"
          placeholder="Enter site address"
          component={StyledLocationSearchField}
          type="text"
          label="Deliver to"
          validate={(value: Location) => {
            if (value && value.place_id) {
              if (value.place_id === task?.supplier?.place_id) {
                return 'Enter or select a different address for delivery'
              }
            }
            return ''
          }}
        />
      </Row>

      <>
        <Row>
          <SupplierDeliverDateField
            name="delivery_datetime"
            label="Deliver date"
            placeholder="Select a deliver by date"
            allowDeselect={false}
            component={PillSelectField}
            options={supplierDeliveryDateOptions()}
            isSelected={(option: string, value: string, options: Array<{ option: string; value: string }>) => {
              const optionsValues = options.map(opt => opt.value)
              const valueStartOfDay = DateTime.fromISO(value).startOf('day').toISO()
              if (option === valueStartOfDay) {
                return true
              } else if (option === 'custom' && value && optionsValues.indexOf(valueStartOfDay) === -1) {
                return true
              }
              return false
            }}
            controlDisplay={values.delivery_datetime ? dateDisplayFormatter(values.delivery_datetime) : undefined}
            onSelect={(date: SupplierDeliveryDate) => {
              if (date === SupplierDeliveryDate.Custom) {
                setFieldValue('delivery_datetime', '')
                setShowCustomDeliveryDatetimeModal(true)
              }
            }}
          />
        </Row>
        <Row>
          <SupplierDeliverTimeField
            name="delivery_type"
            label=""
            placeholder="Select a deliver by time"
            allowDeselect={false}
            component={PillSelectField}
            options={getLabeledSupplierDeliveryTypeConfigs(marketTimeZone)}
            onSelect={(deliveryType: any) => {
              const typeLabel = SupplierDeliveryTypeConfigs[deliveryType].label
              setSupplierDeliveryTypeNote(typeLabel)
            }}
          />
        </Row>
      </>

      <Row>
        <Field name="delivery_note" label="Note" placeholder="Add note here (optional)" component={TextInputField} />
      </Row>
      <Row>
        <MaterialListField<DeliveryDetailsFormValues>
          jobSiteId={jobSiteId}
          errors={errors}
          setFieldValue={setFieldValue}
          taskItems={values.taskItems}
        />
      </Row>
      <Row>
        <SubmitButton size="large" expand="block" onClick={submitForm}>
          Next
        </SubmitButton>
      </Row>
      <Field
        name="delivery_datetime"
        isOpen={showCustomDeliveryDatetimeModal}
        component={DatetimeModalField}
        close={() => setShowCustomDeliveryDatetimeModal(false)}
        presentation={supplierDeliveryFeatureFlag && 'date'}
        dateMin={DateTime.now().plus({ day: 1 })}
      />
    </div>
  )
}

export default compose(
  withFormik<DeliveryDetailsFormProps, DeliveryDetailsFormValues>({
    displayName: 'DeliveryDetailsForm',
    enableReinitialize: true,
    validationSchema: DELIVERY_DETAILS_VALIDATION_SCHEMA,
    mapPropsToValues: ({ task, taskItems, jobSiteId }) => ({
      ...task,
      location: { full_address: task.delivery_address },
      taskItems,
      job_site_id: jobSiteId,
      delivery_by: DeliveryBy.Supplier,
      vehicle_type: VehicleType.Supplier,
    }),
    handleSubmit(
      values: DeliveryDetailsFormValues,
      { props: { onSubmit } }: FormikBag<DeliveryDetailsFormProps, DeliveryDetailsFormValues>
    ) {
      onSubmit(omit(values, 'location'))
    },
  }),
  observer
)(DeliveryDetailsForm)
