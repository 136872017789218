import React, { useEffect } from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import { IonPage, useIonModal } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import { toJS } from 'mobx'
import { TaskType, TaskTypes, JobSiteDetailRouteParams } from '@supplyhound/types'
import Content from '@supplyhound/components/Content'
import { JOB_SITE_TASK_DETAIL_ROUTE } from '@supplyhound/utils/config'
import TaskTypeForm from '@supplyhound/forms/TaskTypeForm'
import { pick } from 'lodash'
import { Header, HeaderModes } from '@supplyhound/layout'

import PickUpDetailsForm from '@supplyhound/forms/DetailsForm/PickUpDetailsForm'
import { DeliveryDetailsForm, DeliveryDetailsFormValues } from '@supplyhound/white-label-components'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import Spacer from '@supplyhound/components/common/Spacer'
import Icon from '@supplyhound/components/common/Icon'
import { helpCircleOutline } from 'ionicons/icons'
import { DeliveryFormHelpModal } from '@supplyhound/white-label-components'

const JobSiteTaskEditPage: React.FC = () => {
  const history = useHistory()
  const { loadWhile } = useLoadingState()
  const { userProfileStore, jobSitesStore } = useStores()
  const { id } = useParams<JobSiteDetailRouteParams>()
  const jobSiteId = parseInt(id)
  const jobSite = jobSitesStore.jobSites[jobSiteId]
  const task = jobSitesStore.getJobSiteTask(jobSiteId)

  const handleTaskTypeChange = (type: TaskType) => {
    jobSitesStore.updateJobSiteTask(jobSiteId, { type })
  }

  const handleTaskDetailsFormSubmit = (type: TaskType) => (values: DeliveryDetailsFormValues) => {
    jobSitesStore.updateJobSiteTask(jobSiteId, {
      ...values,
      type,
    })
    jobSitesStore.selectJobSiteTaskItems(jobSiteId, values.taskItems)
    history.push(generatePath(JOB_SITE_TASK_DETAIL_ROUTE, { id }))
  }

  const taskType = task?.type

  const list = jobSitesStore.getJobSiteOrdererList(jobSiteId)
  const selectedActiveItems = list?.active_items ? toJS(list.active_items.filter(({ selected }) => selected)) : []

  useEffect(() => {
    loadWhile(jobSitesStore.dispatchFetchJobSites)
  }, [])

  useEffect(() => {
    if (jobSite && !list) {
      loadWhile(() => jobSitesStore.listStore.dispatchFetchList(jobSite.orderer_list.id))
    }
  }, [jobSite, list])

  const [presentHelp, dimissHelp] = useIonModal(DeliveryFormHelpModal, {
    dismiss: () => dimissHelp(),
    isWillCall: task?.type === TaskTypes.Pickup,
  })

  return (
    <IonPage>
      <Header
        mode={HeaderModes.Leaf}
        label="Pickup or delivery"
        end={
          <div onClick={() => presentHelp()}>
            <Icon icon={helpCircleOutline} width={30} />
          </div>
        }
      />
      <Content>
        <TaskTypeForm onChange={handleTaskTypeChange} taskType={taskType} />
        {task && taskType === TaskTypes.Delivery && (
          <DeliveryDetailsForm
            task={toJS(
              pick(task, [
                'delivery_type',
                'delivery_by',
                'vehicle_type',
                'delivery_address',
                'delivery_datetime',
                'pickup_datetime',
                'delivery_note',
              ])
            )}
            taskItems={selectedActiveItems}
            onSubmit={handleTaskDetailsFormSubmit(TaskTypes.Delivery)}
            timezone={userProfileStore.marketTimezone}
            jobSiteId={jobSiteId}
          />
        )}
        {task && taskType === TaskTypes.Pickup && (
          <PickUpDetailsForm
            task={toJS(pick(task, ['pickup_datetime', 'pickup_type', 'pickup_note', 'order_no', 'pickup_address']))}
            taskItems={selectedActiveItems}
            onSubmit={handleTaskDetailsFormSubmit(TaskTypes.Pickup)}
            timezone={userProfileStore.marketTimezone}
            jobSiteId={jobSiteId}
          />
        )}
        <Spacer height={50} />
      </Content>
    </IonPage>
  )
}

export default observer(JobSiteTaskEditPage)
