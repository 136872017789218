import {
  HOME_ROUTE,
  HISTORY_ROUTE,
  MANAGE_JOB_SITES_SELECT_ROUTE,
  ACCOUNT_ROUTE,
  HELP_ROUTE,
} from '@supplyhound/utils/config'

import { fingerPrintOutline, helpCircleOutline } from 'ionicons/icons'

interface MenuItems {
  title: string
  routeProps: {
    routeMatcher: RegExp
    routerLink: string
  }
}
interface DropdownItems {
  title: string
  icon: string
  routeProps: {
    routerLink: string
  }
}

export const whiteLabelMenuItems: MenuItems[] = [
  {
    title: 'Home',
    routeProps: {
      routeMatcher: /^(?!manage)\/(home|job-sites)/,
      routerLink: HOME_ROUTE,
    },
  },
  {
    title: 'Jobsites',
    routeProps: {
      routeMatcher: /\/manage/,
      routerLink: MANAGE_JOB_SITES_SELECT_ROUTE,
    },
  },
  {
    title: 'Orders',
    routeProps: {
      routeMatcher: /\/history/,
      routerLink: HISTORY_ROUTE,
    },
  },
]

export const whiteLabelDropdownItems: DropdownItems[] = [
  {
    title: 'Profile',
    icon: fingerPrintOutline,
    routeProps: {
      routerLink: `${ACCOUNT_ROUTE}?tab=profile`,
    },
  },
]

export const getMenuItems = (
  type: 'orderer' | 'teammember' | 'whitelabel' | 'none'
): [MenuItems[], DropdownItems[]] => {
  switch (type) {
    case 'whitelabel':
      return [whiteLabelMenuItems, whiteLabelDropdownItems]
    default:
      return [[], []]
  }
}
