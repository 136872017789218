import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonToast } from '@ionic/react'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { createPasswordReset, updatePasswordReset } from '@supplyhound/white-label-components/api/passwordResets'
import ResetPasswordForm, { ResetPasswordFormValues } from '@supplyhound/forms/ResetPasswordForm'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import { close } from 'ionicons/icons'
import EnterNewPasswordForm, { EnterNewPasswordFormValues } from '@supplyhound/forms/EnterNewPasswordForm'

export type LoginFlowStateType = 'login' | 'reset_password' | 'enter_password'

const LoginModal = ({
  setLoginFlowState,
  loginFlowState,
  dismiss,
  email,
  token,
  supplierId,
}: {
  setLoginFlowState: (state: LoginFlowStateType) => any
  loginFlowState: LoginFlowStateType
  dismiss: () => void
  email: string
  token: string
  supplierId: string
}) => {
  const { loadWhile } = useLoadingState()
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState(
    "Enter your email if you forgot your password or need a new one. We'll send a link to reset it."
  )

  const handleSubmitResetPassword = async ({ email }: ResetPasswordFormValues) => {
    loadWhile(() =>
      createPasswordReset(email, supplierId, '/login')
        .then(() => {
          setShowToast(true)
          setMessage('Check your email for a recovery link.')
        })
        .catch(() => console.error('Request password reset failed'))
    )
  }

  const handleSubmitEnterPassword = ({ new_password }: EnterNewPasswordFormValues) =>
    loadWhile(() =>
      updatePasswordReset(email, supplierId, new_password, token)
        .then(() => {
          dismiss()
        })
        .catch(() => {
          console.log('Oops. Something went wrong')
        })
    )

  return (
    <div>
      {loginFlowState === 'reset_password' && (
        <ResetPasswordForm
          message={message}
          onSubmit={handleSubmitResetPassword}
          onBackToLoginClick={dismiss}
          email={email}
        />
      )}
      {loginFlowState === 'enter_password' && <EnterNewPasswordForm onSubmit={handleSubmitEnterPassword} />}
      <IonToast
        isOpen={showToast}
        mode="ios"
        color="dark"
        onDidDismiss={() => setShowToast(false)}
        message="Check your email for a recovery link."
        position="top"
        buttons={[
          {
            icon: close,
            role: 'cancel',
          },
        ]}
      />
    </div>
  )
}

export default observer(LoginModal)
