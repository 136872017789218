import React, { useState, ChangeEvent, useEffect } from 'react'
import { Field, FieldProps, FormikBag, FormikProps, withFormik, getIn } from 'formik'
import { InputChangeEventDetail, IonInputCustomEvent } from '@ionic/core'
import { IonInput } from '@ionic/react'
import styled from 'styled-components'
import SupplierSearchField from '@supplyhound/forms/fields/SupplierSearchField'
import * as Yup from 'yup'
import SubmitButton from '@supplyhound/components/buttons/SubmitButton'
import { SupplierSearchResult } from '@supplyhound/types'
import Spacer from '@supplyhound/components/common/Spacer'
import ErrorLabel from '@supplyhound/components/common/ErrorLabel'
import SupplierOptions from '@supplyhound/components/SupplierOptions'
import { withInputBorder } from '@supplyhound/forms/fields/InputBorder'
import FieldHeader from '@supplyhound/forms/fields/FieldHeader'
import { parseNameWithCity } from '@supplyhound/utils/address'

type SupplierSearchFormProps = {
  supplier?: SupplierSearchResult
  supplierLocations?: { name: string; address: string; displayText: string; place_id: string; supplier_id?: number }[]
  onSubmit: (values: SupplierSearchFormValues) => void
  label: string
}

const StyledInput = styled(withInputBorder(IonInput))`
  background-color: var(--greyscale-1);
  border-radius: var(--ion-border-radius);
  --padding-start: 10px;
  height: 50px;
`

export type SupplierSearchFormValues = {
  supplier: SupplierSearchResult
}

const SupplierSearchForm: React.FC<SupplierSearchFormProps & FormikProps<SupplierSearchFormValues>> = ({
  submitForm,
  label,
  setFieldValue,
  supplierLocations,
}) => {
  const [controlText, setControlText] = useState('')
  const [showNextButton, setShowNextButton] = useState<boolean>(false)
  const [showSupplierOptions, setShowSupplierOptions] = useState(true)
  const [supplierOptions, setSupplierOptions] = useState(supplierLocations)

  const handleControlChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const text = event.detail.value || ''
    if (text) {
      const regexp = new RegExp(text, 'gi')
      const filteredOptions = supplierLocations?.filter(location => location.displayText.match(regexp))
      setSupplierOptions(filteredOptions)
    } else {
      setSupplierOptions(supplierLocations)
    }
  }

  const handleOnInput = (event: IonInputCustomEvent<InputEvent>) => {
    const text = event.target.value || ''

    if (text) {
      setControlText(text.toString())
    } else {
      setControlText('')
      setShowNextButton(false)
    }
  }

  const controlTextDisplay = (supplier: SupplierSearchResult) => {
    if (supplier.supplier_id && supplier.displayText) {
      return supplier.displayText
    } else if (supplier.supplier_id) {
      return supplier.name
    } else {
      return parseNameWithCity(supplier.address)
    }
  }

  return (
    <>
      <>
        <FieldHeader label={label} />
        <Field
          name="supplier"
          children={(props: FieldProps<Partial<SupplierSearchResult>>) => {
            const form = props.form
            const { name } = props.field
            const error = getIn(form.errors, `${name}.address`)
            const touched = getIn(form.touched, name)

            return (
              <>
                <StyledInput
                  placeholder="Enter and select supplier name or address"
                  onIonChange={handleControlChange}
                  onIonInput={handleOnInput}
                  debounce={400}
                  onIonBlur={() => form.setFieldTouched(name)}
                  value={controlText}
                  onClick={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target) {
                      event.target.select()
                    }
                    setShowSupplierOptions(true)
                  }}
                />
                {error && touched && <ErrorLabel>{error}</ErrorLabel>}

                {showNextButton && (
                  <>
                    <Spacer height={20} />
                    <SubmitButton onClick={submitForm}>Next</SubmitButton>
                  </>
                )}
                {showSupplierOptions && (
                  <SupplierOptions
                    supplierOptions={supplierOptions || []}
                    sectionLabel={'Locations'}
                    onSupplierSelected={supplier => {
                      setFieldValue('supplier', supplier)
                      setControlText(controlTextDisplay(supplier))
                      setShowNextButton(true)
                      setShowSupplierOptions(false)
                    }}
                  />
                )}
              </>
            )
          }}
        />
      </>
    </>
  )
}

export default withFormik<SupplierSearchFormProps, SupplierSearchFormValues>({
  displayName: 'SupplierSearchForm',
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    supplier: Yup.object()
      .shape({
        name: Yup.string().required('Search for and select a street address'),
        supplier_id: Yup.string().nullable(),
        address: Yup.string().required('Search for and select a street address'),
      })
      .required('Required'),
  }),
  validateOnMount: true,

  mapPropsToValues: ({ supplier }) => ({
    supplier: supplier ?? {
      name: '',
      address: '',
    },
  }),
  handleSubmit(
    values: SupplierSearchFormValues,
    { props: { onSubmit } }: FormikBag<SupplierSearchFormProps, SupplierSearchFormValues>
  ) {
    onSubmit(values)
  },
})(SupplierSearchForm)
