import React, { useEffect } from 'react'
import JobSitesFilter from '@supplyhound/components/JobSitesFilter'
import { fetchItemHistory } from '@supplyhound/api'
import Card from '@supplyhound/components/common/Card'

import styled from 'styled-components'
import Spacer from '@supplyhound/components/common/Spacer'
import { IonSpinner } from '@ionic/react'
import Icon from '@supplyhound/components/common/Icon'
import { chevronForwardOutline, copyOutline } from 'ionicons/icons'
import useStores from '@supplyhound/hooks/useStores'

import { OrdererListItemModalForm, FormModes } from '@supplyhound/white-label-components'
import { HISTORY_JOBSITE_SELECT, JOB_SITE_LIST_DETAIL_ROUTE } from '@supplyhound/utils/config'
import { generatePath, useHistory } from 'react-router-dom'
import { AbstractItem, UnidentifiedAbstractItem } from '@supplyhound/types'
import useLoadingState from '@supplyhound/hooks/useLoadingState'
import { pick } from 'lodash'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const LoadingContainer = styled(FlexContainer)`
  height: 100px;
  justify-content: center;
  margin-top: 100px;
`

const Img = styled.img`
  max-width: 50px;
  max-height: 50px;
  margin: 0px 10px;
`

const Description = styled.div<{ hasImage: boolean }>`
  margin: 25px 0px;
  ${({ hasImage }) => (hasImage ? '' : 'margin-left: 25px;')}
`

const Chevron = styled(Icon)`
  margin-left: auto;
  margin-right: 10px;
`

const Spinner = styled(IonSpinner)`
  transform: scale(3);
`

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 50px;
  text-align: center;
`

const StyledCard = styled(Card)`
  cursor: pointer;
`

type HistoryItemsProps = {
  jobSiteId?: number
}

const HistoryItems = (props: HistoryItemsProps = {}) => {
  const history = useHistory()
  const { loadWhile } = useLoadingState()

  const { jobSitesStore, historyStore } = useStores()
  const [selectedJobsiteId, setSelectedJobsiteId] = React.useState<number | undefined>(undefined)
  const [itemForReorder, setItemForReorder] = React.useState<UnidentifiedAbstractItem | undefined>(undefined)

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  // we can optimize this by putting this data in a store
  const [items, setItems] = React.useState<AbstractItem[]>([])

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    fetchItemHistory(selectedJobsiteId).then(data => {
      setItems(data.task_items)
      setIsLoading(false)
    })
  }, [selectedJobsiteId])

  return (
    <div>
      <JobSitesFilter onChange={siteId => setSelectedJobsiteId(siteId)} />
      {isLoading && (
        <LoadingContainer>
          <Spinner name="circles" />
        </LoadingContainer>
      )}
      <div>
        {!isLoading &&
          items.map(item => {
            return (
              <StyledCard key={item.id}>
                <FlexContainer
                  onClick={() => {
                    setModalOpen(true)
                    setItemForReorder({
                      description: item.description,
                      image: item.image,
                      source_task_item_id: item.id,
                      quantity: '',
                    })
                  }}
                >
                  {item.image?.thumbnail_url && (
                    <>
                      <Img src={item.image.thumbnail_url} alt="item" />
                      <Spacer width={10} />
                    </>
                  )}
                  <Description hasImage={!!item.image?.thumbnail_url}>{item.description}</Description>
                  <Chevron icon={chevronForwardOutline} />
                </FlexContainer>
              </StyledCard>
            )
          })}
        {!isLoading && items.length === 0 && (
          <EmptyContainer>
            <Icon icon={copyOutline} size="large" width={50} />
            <Spacer height={30} />
            <div>Your item list will appear here.</div>
          </EmptyContainer>
        )}
      </div>
      <OrdererListItemModalForm
        item={itemForReorder || undefined}
        isOpen={modalOpen}
        onSubmit={async item => {
          const jobSiteId = props.jobSiteId
          if (jobSiteId) {
            const jobsite = jobSitesStore.jobSites[jobSiteId]
            const listId = jobsite?.orderer_list?.id
            if (!listId) return
            await loadWhile(() =>
              historyStore
                .dispatchOrderListItem(
                  listId,
                  pick(item, ['description', 'quantity', 'image']),
                  item.source_task_item_id!
                )
                .then(newItem => {
                  jobSitesStore.setJobSiteTaskItemSelectState(jobSiteId, newItem.id!, true)
                })
            )
            setModalOpen(false)
            history.push(generatePath(JOB_SITE_LIST_DETAIL_ROUTE, { id: jobSiteId }))
          } else {
            setModalOpen(false)
            history.push({
              pathname: HISTORY_JOBSITE_SELECT,
              state: { item },
            })
          }
        }}
        onCancel={() => {
          setModalOpen(false)
        }}
        mode={FormModes.Add}
      />
    </div>
  )
}

export default HistoryItems
