import { axiosInstance } from '@supplyhound/api/axios'

export const createPasswordReset = (email: string, supplierId: string, updatePath: string) =>
  axiosInstance.post('/v1/password_resets', {
    password_reset: { email, supplier_id: supplierId, nupdate_path: updatePath },
  })

export const updatePasswordReset = (email: string, supplier_id: string, password: string, token: string) =>
  axiosInstance
    .patch(`/v1/password_resets/${token}`, {
      password_reset: { email, password },
    })
    .then(({ data }) => data.attributes)
