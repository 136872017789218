import React from 'react'
import { useHistory } from 'react-router-dom'
import { IonPage } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '@supplyhound/hooks/useStores'
import { SignupForm } from '@supplyhound/white-label-components'
import Content from '@supplyhound/components/Content'
import { Header, HeaderModes } from '@supplyhound/layout'

import { signUp } from '@supplyhound/api'
import { User } from '@supplyhound/types'
import { HOME_ROUTE } from '@supplyhound/utils/config'
import { SUPPLIER_ID } from 'config'
import { useLoadingState } from '@supplyhound/hooks'

const SignUpPage: React.FC = () => {
  const history = useHistory()
  const { userProfileStore } = useStores()
  const { loadWhile } = useLoadingState()

  const handleSubmit = async (user: User) => {
    loadWhile(async () => {
      const { data } = await signUp({ ...user, supplier_id: SUPPLIER_ID || '', market_id: '' })

      // Set token and load profile
      await userProfileStore.authStore.setToken(data.jwt)
      await userProfileStore.dispatchLoadProfile()

      history.push({ pathname: HOME_ROUTE })
    })
  }

  return (
    <IonPage>
      <Header label="Create Account" mode={HeaderModes.Leaf} />
      <Content>
        <SignupForm marketOptions={[]} onSubmit={handleSubmit} />
      </Content>
    </IonPage>
  )
}

export default observer(SignUpPage)
