import React from 'react'
import { IonPage } from '@ionic/react'
import { Header, HeaderModes } from '@supplyhound/layout'

import Content from '@supplyhound/components/Content'
import { observer } from 'mobx-react-lite'
import AccountProfileTab from 'pages/Account/AccountProfileTab'

const AccountPage: React.FC = () => {
  return (
    <IonPage>
      <Header mode={HeaderModes.Root} label="Account" />
      <Content>
        <AccountProfileTab />
      </Content>
    </IonPage>
  )
}

export default observer(AccountPage)
